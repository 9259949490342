
import { Component, Inject, Vue } from "vue-property-decorator";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";

@Component({
  name: "QuickPurchaseHeader",
  components: { PageView, PageSection },
})
export default class QuickPurchaseHeader extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  displayShowHostedPlanCta: boolean = false;

  get quickPurchaseHeaderWrapperClasses() {
    const classes: string[] = [this.$mq];
    return classes;
  }

  handleShouldDisplayHostedPlanCta(shouldDisplay: boolean) {
    this.displayShowHostedPlanCta = shouldDisplay;
  }
  createEventsBusHandlers() {
    this.quickPurchaseBus.$on("shouldDisplayHostedPlanCta", this.handleShouldDisplayHostedPlanCta);
  }

  created() {
    this.createEventsBusHandlers();
  }
}
