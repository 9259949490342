
import { Component, Provide, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { QuickPurchaseData } from "@/types/localtypes";
import QuickPurchaseHeader from "@/components/landingPages/quickPurchase/QuickPurchaseHeader.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";

@Component({
  name: "QuickPurchase",
  components: { PageView, PageSection, QuickPurchaseHeader },
})
export default class QuickPurchase extends Vue {
  @Provide() quickPurchaseBus = new Vue();
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  displayShowHostedPlanCta: boolean = false;
  jfrogWebsiteHostedPageUrl = "https://jfrog.com";

  toHostedPlans() {
    window.open(this.jfrogWebsiteHostedPageUrl);
  }

  get isMobile() {
    return this.$mq === "mobile";
  }
  get isTablet() {
    return this.$mq === "tablet";
  }
  get quickPurchaseWrapperClasses() {
    const classes: string[] = [this.$mq];
    return classes;
  }

  get transitionName() {
    switch (this.$route.name) {
      case "quick-purchase-pricing":
      case "quick-purchase-thank-you":
        return "page-to-right";
      case "quick-purchase-checkout":
      case "quick-purchase-get-a-quote":
        return "page-to-left";
      default:
        return "fade";
    }
  }

  handleShouldDisplayHostedPlanCta(shouldDisplay: boolean) {
    this.displayShowHostedPlanCta = shouldDisplay;
  }

  get serverName() {
    return this.quickPurchaseData.serverName;
  }

  handleGoToPlatform(platformUrl: string) {
    const url = platformUrl || `https://${this.serverName}.jfrog.io/`;
    window.open(url);
  }

  createEventsBusHandlers() {
    this.quickPurchaseBus.$on("shouldDisplayHostedPlanCta", this.handleShouldDisplayHostedPlanCta);
    this.quickPurchaseBus.$on("shouldGoToPlatform", this.handleGoToPlatform);
  }

  created() {
    this.createEventsBusHandlers();
  }
}
